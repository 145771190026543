<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import TicketsbyMonth from "./Tickets by month/TicketsbyMonth.vue";
import TicketsbyBranch from './Tickets by branch/TicketsbyBranch.vue';
import SolvedUnsolved from "./solved unsolved report/SolvedUnsolved.vue";
import TicketsbyPriority from "./Tickets by priority/TicketsbyPriority.vue";
import TicketsbyRemark from "./Tickets by Remark/TicketsbyRemark.vue";
import TicketsbyType from "./Tickets by Type/TicketsbyType.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    TicketsbyMonth,
    TicketsbyBranch,
    SolvedUnsolved,
    TicketsbyPriority,
    TicketsbyRemark,
    TicketsbyType
  },
  data() {
    return {
    };
  },
  methods: {
   

  },
  created() {
   
  }
};
</script>

<template>
  <Layout>
   
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('report.tickets-report')" />
    <b-tabs   :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
      <b-tab active>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{$t("report.tickets-by-branch")}}</span>
        </template>
        <ticketsby-branch></ticketsby-branch>
      <!-- end emp tab-->
      </b-tab>
      <b-tab>
      <template v-slot:title>
        <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
          <i class="fas fa-home"></i>
        </span>
        <span class="d-none d-sm-inline-block">{{$t("report.tickets-by-type")}} </span>
      </template>
      <ticketsby-type></ticketsby-type>
</b-tab>

      <b-tab>
        <template v-slot:title>
          <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{$t("report.tickets-by-date")}} </span>
        </template>
        <ticketsby-month></ticketsby-month>
</b-tab>

<b-tab>
    <template v-slot:title>
      <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
        <i class="fas fa-home"></i>
      </span>
      <span class="d-none d-sm-inline-block">{{$t("report.tickets-by-solve")}} </span>
    </template>
    <solved-unsolved></solved-unsolved>
</b-tab>

<b-tab>
  <template v-slot:title>
    <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
      <i class="fas fa-home"></i>
    </span>
    <span class="d-none d-sm-inline-block">{{$t("report.tickets-by-priority")}} </span>
  </template>
 <ticketsby-priority></ticketsby-priority>
</b-tab>

<b-tab>
  <template v-slot:title>
    <span  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="d-inline-block d-sm-none">
      <i class="fas fa-home"></i>
    </span>
    <span class="d-none d-sm-inline-block">{{$t("report.tickets-by-remark")}} </span>
  </template>
  <ticketsby-remark></ticketsby-remark>
</b-tab>
</b-tabs>
  
  
  
  

  </Layout>
</template>
<style scoped>
</style>




