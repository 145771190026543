<script>
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
  
  },
  data() {
    return {
        del: "",
      tickets: [],
      issue: [],
      customer: [],
      Tickets: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      pageres: 1,
      limitres: 20,
      fromCheckRemark: false,
      searchTickets: null,
      TicketsSearchMode: false,
      ticket_resolutions: [],
      TicketResolutions: {},
      emp: [],
      ticket_id: 0,
      data:{},
      searchTicketResolutions: null,
      TicketResolutionsSearchMode: false,
      branches:[]
    };
  },
  methods: {
    searchRemark(){
    this.http.post("tickets/report",this.data).then(res=>{
      this.tickets = res.data
      this.data = {};
    })
   },
    enableFromTo(){
      console.log(this.fromCheckRemark);
      if(this.fromCheckRemark == true){
        console.log("from");
        document.getElementById('oneDateRemark').setAttribute('disabled','disabled')
        document.getElementById('fromRemark').removeAttribute('disabled');
        document.getElementById('toRemark').removeAttribute('disabled');
        delete this.data['date']
      }
      if(this.fromCheckRemark == false){
        console.log("one");
        document.getElementById('oneDateRemark').removeAttribute('disabled');
        document.getElementById('fromRemark').setAttribute('disabled','disabled')
        document.getElementById('toRemark').setAttribute('disabled','disabled')
        delete this.data['from']
        delete this.data['to']
      }
    },
  },
  created() {
  },
};
</script>

<template>
 
    <form class="row gx-3 gy-2 align-items-center mb-4" @submit.prevent="searchRemark()">
        <div class="col">
            <label class="inline" for="specificSizeInputName">{{$t("report.select-remark")}}</label>
            <select required v-model="data.remark" class="form-select">
              <option value="1">{{$t("popups.accepted")}}</option>
              <option value="0">{{$t("popups.rejected")}}</option>
            </select>
          </div>
        <div class="col">
            <label class="inline" for="oneDateRemark">{{$t("report.date")}}</label>
            <input  v-model="data.date" type="date" class="form-control oneDate" id="oneDateRemark">
        </div>
        <div class="col">
            <div class="form-check" style="display:flex;justify-content:center;top: 15px">
                <input v-model="fromCheckRemark" @change="enableFromTo()" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label v-if="$i18n.locale == 'en'" style="margin-left: 5px" class="form-check-label" for="flexCheckDefault">
                  {{ $t("report.from-to") }}
                </label>
                <label v-if="$i18n.locale == 'ar'" style="margin-right: 25px" class="form-check-label" for="flexCheckDefault">
                  {{ $t("report.from-to") }}
                </label>
              </div>
        </div>
        <div class="col">
            <label class="inline" for="fromRemark">{{ $t("report.from") }}</label>
            <input  disabled v-model="data.from" type="date" class="form-control" id="fromRemark">
        </div>
         <div class="col">
            <label class="inline" for="toRemark">{{ $t("report.to") }}</label>
            <input  disabled v-model="data.to" type="date" class="form-control fromto" id="toRemark">
        </div>
        <div class="col-auto" style="top:14px">
          <button type="submit" class="btn btn-primary">{{ $t("popups.search") }}</button>
        </div>
      </form>
   

      <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"  class="table">
        <thead>
          <tr class=" text-light text-center" style="background-color: #2a3042 !important;">
            <th scope='col'>{{ $t("tickets.id") }}</th>
            <th scope='col'>{{ $t("tickets.issuetype") }}</th>
            <th scope='col'>{{ $t("tickets.customer") }}</th>
            <th scope='col'>{{ $t("tickets.description") }}</th>
            <th scope='col'>{{ $t("tickets.status") }}</th>
            <th scope="col">{{ $t("tickets.priority")}}</th>
            <th scope='col' style="width:2%"> {{ $t("tickets.created") }}</th>
            <th scope='col'>{{ $t("tickets.updated") }}</th>
          </tr>
        </thead>
        <tbody>
  
          <tr v-for="(searchIn, index) in tickets" :key="searchIn" class="text-center">
            <td>{{ index+ 1}}</td>
            <td>{{ searchIn?.issue_type?.name }}</td>
            <td>{{ searchIn?.customer?.name }}</td>
            <td v-html=searchIn?.description></td>
            <td>
              
              <h6 v-if="searchIn?.status == 0" class="text-danger">{{ $t("popups.underprogress") }}</h6>
              <span v-if="searchIn?.status == 1" class="text-success">{{ $t("popups.finished") }}</span>
            </td>
            <td>
              <span class="text-danger" v-if="searchIn.priority == 1">{{$t('popups.high')}}</span>
              <span class="text-warning" v-if="searchIn.priority == 2">{{$t('popups.medium')}}</span>
              <span class="text-success" v-if="searchIn.priority == 3">{{$t('popups.low')}}</span>
            </td>
            <td>{{ searchIn?.created.split('T')[0] }}</td>
            <td>{{ searchIn?.updated.split('T')[0] }}</td>
          </tr>
        </tbody>
      </table>
   

   
   
    <!--   Apps  Pagination     -->
    <ul v-if="tot_pages >=20" class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->

</template>